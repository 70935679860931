import { sleep } from './sleep';

export default ({ fn, shouldRetry, nbOfRetry = 0, delay = 0 }) => {
  const executeWithRetry = retry =>
    fn().catch(error => {
      // Forward directly the error
      if (retry <= 0 || !shouldRetry(error)) {
        throw error;
      }
      // Retry if the request has been cancelled because of a shaky connection for instance
      return sleep(delay).then(() => executeWithRetry(retry - 1, delay));
    });
  return executeWithRetry(nbOfRetry);
};
