import RBackend from './r';
import PythonBackend from './python';
import ScalaBackend from './scala';
import JuliaBackend from './julia';

export default language => {
  switch (language) {
    case 'julia':
      return new JuliaBackend('julia');
    case 'r':
      return new RBackend('r');
    case 'revo':
      return new RBackend('revo');
    case 'python':
      return new PythonBackend('python');
    case 'sql':
      return new PythonBackend('sql');
    case 'shell':
      return new PythonBackend('shell');
    case 'scala':
      return new ScalaBackend('scala');
    case 'containers':
      return new PythonBackend('containers');
    default:
      throw new Error(`no backend for language ${language}`);
  }
};
