import { v4 as uuid } from 'uuid';

type JsonRpcId = number | string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JsonRpcNamedParams = Record<any, unknown>;
type JsonRpcPositionalParams = unknown[];
export type JsonRpcRequest = {
  id: JsonRpcId;
  jsonrpc: '2.0';
  method: string;
  params?: JsonRpcNamedParams | JsonRpcPositionalParams;
};
export type JsonRpcBatchRequest = JsonRpcRequest[];
type RawJsonRpcNotification = Omit<JsonRpcRequest, 'id'>;
export type JsonRpcNotification<T = Partial<RawJsonRpcNotification>> =
  RawJsonRpcNotification & T;

export type JsonRpcSuccessResponse<T = unknown> = {
  id: JsonRpcId;
  jsonrpc: '2.0';
  result: T;
};
export type JsonRpcErrorResponse = {
  error: {
    code: number;
    data?: unknown;
    message: string;
  };
  id: JsonRpcId;
  jsonrpc: '2.0';
};
export type JsonRpcResponse<T = unknown> =
  | JsonRpcErrorResponse
  | JsonRpcSuccessResponse<T>;
export type JsonRpcBatchResponse = JsonRpcResponse[];

export type JsonRpcMessage =
  | JsonRpcBatchRequest
  | JsonRpcBatchResponse
  | JsonRpcNotification
  | JsonRpcRequest
  | JsonRpcResponse;

export const createRequestMessage = (
  method: string,
  params: JsonRpcRequest['params'],
): JsonRpcRequest => {
  return {
    id: uuid(),
    jsonrpc: '2.0',
    method,
    params,
  };
};
export const createNotificationMessage = (
  method: string,
  params: JsonRpcRequest['params'],
): JsonRpcNotification => {
  return {
    jsonrpc: '2.0',
    method,
    params,
  };
};

export const onMessage =
  ({
    onBatchMessage,
    onNotification,
    onRequest,
    onResponse,
  }: {
    onBatchMessage: (
      message: JsonRpcBatchRequest | JsonRpcBatchResponse,
    ) => void;
    onNotification: (notification: JsonRpcNotification) => void;
    onRequest: (request: JsonRpcRequest) => void;
    onResponse: (response: JsonRpcResponse) => void;
  }) =>
  (message: JsonRpcMessage) => {
    if (Array.isArray(message)) {
      onBatchMessage(message);
    } else if (!('id' in message)) {
      onNotification(message);
    } else if ('method' in message) {
      onRequest(message);
    } else {
      onResponse(message);
    }
  };
