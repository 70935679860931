import PythonBackend from './python';

const scalaFilenameMap = {
  console: '<stdin>',
  submit: 'script.scala',
};

class ScalaBackend extends PythonBackend {
  // eslint-disable-next-line class-methods-use-this
  formatCommand({
    type = 'NormalExercise',
    pec = '',
    sct = '',
    solution = '',
    command = 'submit',
    code = '',
    clientEnvironment = null,
  }) {
    const filename = scalaFilenameMap[command];
    const forceDiagnose = clientEnvironment === 'validation';

    const data = {
      DC_CODE: code,
      DC_PEC: pec,
      DC_SCT: sct,
      DC_SOLUTION: solution,
      DC_TYPE: type,
      DC_FILENAME: filename,
      DC_FORCE_DIAGNOSE: forceDiagnose,
      DC_CLIENT_ENVIRONMENT: clientEnvironment,
    };

    return data;
  }
}

export default ScalaBackend;
