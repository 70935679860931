import attempt from 'lodash.attempt';
import isError from 'lodash.iserror';

const unmangleJSON = response =>
  response.replace(/([{[,])\\n/g, '$1').replace(/\\(.)/g, '$1');

export default (responseData, responseRegex) => {
  let match;
  let responses = [];
  if (responseData === '') return null;
  // eslint-disable-next-line no-cond-assign
  while ((match = responseRegex.exec(responseData)) !== null) {
    const unmangle = unmangleJSON(match[1]);
    const result = attempt(() => JSON.parse(unmangle));
    if (!isError(result)) {
      responses = responses.concat(result);
    }
  }
  return responses;
};
