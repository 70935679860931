import formatResponseData from './formatResponseData';

class RBackend {
  constructor(language) {
    this.language = language;
  }

  // eslint-disable-next-line class-methods-use-this
  formatCommand({
    width = 640,
    height = 640,
    type = 'NormalExercise',
    pec = '',
    sct = '',
    solution = '',
    command = 'submit',
    code = '',
    activeTab,
    figureIndex,
    format,
    autoComp = '',
    cursorPos = 0,
    echo = false,
    clientEnvironment = null,
  }) {
    const forceDiagnose = clientEnvironment === 'validation';

    return {
      DC_CODE: code,
      DC_PEC: pec,
      DC_SCT: sct,
      DC_SOLUTION: solution,
      DC_RENDER_HEIGHT: height,
      DC_RENDER_WIDTH: width,
      DC_TYPE: type,
      DC_COMMAND: command,
      DC_ACTIVE_TAB: activeTab,
      DC_ECHO: echo,
      DC_FIGURE_INDEX: figureIndex,
      DC_FORMAT: format,
      DC_AUTO_COMP: autoComp,
      DC_CURSOR_POS: cursorPos,
      DC_FORCE_DIAGNOSE: forceDiagnose,
      DC_CLIENT_ENVIRONMENT: clientEnvironment,
    };
  }

  formatRequestPayload(data) {
    // gives us a string that we can send to the backend to run rcall(data)
    const command = this.formatCommand(data);
    // the json needs some fudging before we can use it because it:
    // 1. gets interpreted by R and then by a JSON parser
    //    so we escape all the escapes
    // 2. gets wrapped in single quotes
    //    so we escape all the existing single quotes
    const fudged = JSON.stringify(command)
      .replace(/\\/g, '\\\\')
      .replace(/'/g, "\\'");

    // next we split it into chunks because R can't cope with big
    // (>4096 chars) lines. we need to take care not to split on a
    // backslash because that causes headaches
    const CHUNK_LENGTH = 2048;
    const lines = [];
    let lastEnd = 0;

    while (lastEnd < fudged.length) {
      let end = lastEnd + CHUNK_LENGTH;

      while (fudged[end] === '\\' && end > lastEnd) {
        end -= 1;
      }

      // if someone gives us all backslashes we can take an
      // even number of them. do -1 because we +1 it in just a second
      if (end === lastEnd) {
        end = lastEnd + CHUNK_LENGTH + (CHUNK_LENGTH % 2) - 1; // eslint-disable-line
      }

      // +1 so we include the character at fudged[ end ]
      lines.push(
        `DC_DATA = paste0(DC_DATA, '${fudged.substring(lastEnd, end + 1)}')`
      );
      lastEnd = end + 1;
    }
    return `DC_DATA = ''\n${lines.join('\n')}\nexecute(DC_DATA)`;
  }

  // eslint-disable-next-line class-methods-use-this
  formatResponseData(responseData) {
    return formatResponseData(responseData, RBackend.responseRegex);
  }
}

RBackend.responseRegex = /\[1\] "(.*)"\r?\n/g;

export default RBackend;
