import formatResponseData from './formatResponseData';

class PythonBackend {
  constructor(language) {
    this.language = language;
  }

  formatCommand({
    width = 640,
    height = 640,
    type = 'NormalExercise',
    pec = '',
    sct = '',
    solution = '',
    command = this.language === 'python' ? 'submit' : 'run',
    code = '',
    autoComp = '',
    cursorPos = 0,
    tableName,
    terminalRows = null,
    terminalCols = null,
    clientEnvironment = null,
    featureFlags = {},
  }) {
    const filenameMap = {
      console: '<stdin>',
      bokeh_server: '<stdin>',
      submit: 'script.py',
      auto_complete: 'auto_complete.py',
    };

    const filename = filenameMap[command];
    const forceDiagnose = clientEnvironment === 'validation';

    const data = {
      DC_CODE: code,
      DC_PEC: pec,
      DC_SCT: sct,
      DC_SOLUTION: solution,
      DC_RENDER_WIDTH: width,
      DC_RENDER_HEIGHT: height,
      DC_TYPE: type,
      DC_AUTO_COMP: autoComp,
      DC_CURSOR_POS: cursorPos,
      DC_TABLE: tableName,
      DC_TERMINAL_ROWS: terminalRows,
      DC_TERMINAL_COLS: terminalCols,
      DC_FORCE_DIAGNOSE: forceDiagnose,
      DC_CLIENT_ENVIRONMENT: clientEnvironment,
      DC_FEATURE_FLAGS: featureFlags,
    };
    if (filename) data.DC_FILENAME = filename;
    return data;
  }

  formatRequestPayload(data) {
    // gives us a string that we can send to the backend to run pythonCall(data)

    // the json needs some fudging before we can use it because it:
    // 1. gets interpreted by Python and then by a JSON parser
    //    so we escape all the escapes
    // 2. gets wrapped in single quotes
    //    so we escape all the existing single quotes
    const command = this.formatCommand(data);
    let pythonFunction = data.command;
    if (pythonFunction === 'code_completion') {
      pythonFunction = 'auto_complete';
    } else if (pythonFunction === 'raw') {
      return data.code;
    }
    const fudged = JSON.stringify(command)
      .replace(/\\/g, '\\\\')
      .replace(/'/g, "\\'");
    const payload = `${pythonFunction}('${fudged}')`;
    return payload;
  }

  // eslint-disable-next-line class-methods-use-this
  formatResponseData(responseData) {
    return formatResponseData(responseData, PythonBackend.responseRegex);
  }
}

PythonBackend.responseRegex = /"(.*)"\r?\n/g;

export default PythonBackend;
