import { SyncSession, AsyncSession } from './session';
import Client from './client';

export const PLUGIN_NAME = 'multiplexer-client';
export { SyncSession, AsyncSession } from './session';
export { default as Client } from './client';

export default {
  SyncSession,
  AsyncSession,
  Client,
};
