import PythonBackend from './python';

const juliaFilenameMap = {
  console: '<stdin>',
  submit: 'script.jl',
};

class JuliaBackend extends PythonBackend {
  // eslint-disable-next-line class-methods-use-this
  formatCommand({
    type = 'NormalExercise',
    pec = '',
    sct = '',
    solution = '',
    command = 'submit',
    code = '',
    clientEnvironment = null,
  }) {
    const filename = juliaFilenameMap[command];
    const forceDiagnose = clientEnvironment === 'validation';

    return {
      DC_CODE: code,
      DC_PEC: pec,
      DC_SCT: sct,
      DC_SOLUTION: solution,
      DC_TYPE: type,
      DC_FILENAME: filename,
      DC_FORCE_DIAGNOSE: forceDiagnose,
      DC_CLIENT_ENVIRONMENT: clientEnvironment,
    };
  }
}

export default JuliaBackend;
